import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgLightning = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      d="M20.0481 11.7617L9.54814 23.0117C9.43687 23.1305 9.28999 23.2098 9.12967 23.2377C8.96936 23.2657 8.8043 23.2407 8.65941 23.1667C8.51452 23.0926 8.39766 22.9734 8.32645 22.827C8.25525 22.6807 8.23358 22.5152 8.2647 22.3555L9.63907 15.4808L4.23626 13.452C4.12023 13.4086 4.01675 13.3372 3.93508 13.244C3.85341 13.1508 3.79609 13.0389 3.76823 12.9182C3.74037 12.7975 3.74285 12.6718 3.77545 12.5522C3.80804 12.4327 3.86973 12.3231 3.95501 12.2333L14.455 0.983274C14.5663 0.864526 14.7132 0.785191 14.8735 0.75724C15.0338 0.729289 15.1988 0.75424 15.3437 0.828326C15.4886 0.902412 15.6055 1.02161 15.6767 1.16794C15.7479 1.31428 15.7696 1.47979 15.7384 1.63952L14.3603 8.52171L19.7631 10.5476C19.8783 10.5913 19.981 10.6627 20.062 10.7555C20.143 10.8483 20.2 10.9596 20.2278 11.0796C20.2556 11.1996 20.2534 11.3246 20.2214 11.4436C20.1894 11.5626 20.1286 11.6718 20.0444 11.7617H20.0481Z"
      fill={props.color}
    />
  </svg>
);
export default SvgLightning;
