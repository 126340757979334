import React, { useCallback, useState } from 'react';
import Image from 'next/image';
import { BsChevronLeft, BsList } from 'react-icons/bs';
import SideDrawer from './SideDrawer';
import { useRouter } from 'next/router';
import { useBrandData } from 'src/hooks/useBrandData';
import Button from '@/components/ui/Button';
import Link from 'next/link';
import { useLayoutControls } from 'src/hooks/useLayoutControls';
import clsx from 'clsx';
import { gql } from 'src/__generated__';
import { useQuery } from '@apollo/client';
import useNewBookAgainExperimentCohort from '@/hooks/useNewBookAgainExperimentCohort';
import Icon from '@/components/ui/icon';

const HEADER_QUERY = gql(/* GraphQL */ `
  query HeaderQuery {
    me {
      ... on Customer {
        id
        vouchers {
          id
          merchant
        }
      }
    }
  }
`);

const Header = ({
  menuHidden,
  logoLinkDisabled,
  narrow,
}: {
  menuHidden?: boolean;
  logoLinkDisabled?: boolean;
  narrow?: boolean;
}) => {
  const { logo } = useBrandData();
  const router = useRouter();
  const { backButtonAction, closeButtonAction } = useLayoutControls();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const { loading, data } = useQuery(HEADER_QUERY);
  const hasVoucher =
    data?.me?.__typename === 'Customer' &&
    (data?.me?.vouchers?.length || 0) > 0 &&
    data?.me?.vouchers?.some((voucher) => voucher?.merchant === 'dhj');
  const { hasNewBookAgain, loading: experimentLoading } =
    useNewBookAgainExperimentCohort();

  const onSidebarOpen = useCallback(() => {
    setIsSidebarOpen(true);
  }, [setIsSidebarOpen]);

  const onSidebarClose = useCallback(() => {
    setIsSidebarOpen(false);
  }, [setIsSidebarOpen]);

  return (
    <>
      <SideDrawer isOpen={isSidebarOpen} onClose={onSidebarClose} />
      <div className="flex flex-row fixed top-0 left-0 right-0 sm:h-[60px] lg:h-[70px] bg-white shadow-md z-[100]">
        <header
          className={clsx(
            narrow ? 'max-w-[768px]' : 'max-w-[1280px]',
            'flex bg-white h-full mx-auto sm:px-4 lg:px-10 items-center justify-self-center w-full justify-center flex-row relative',
          )}
        >
          {backButtonAction ? (
            <Button
              className="absolute left-3 sm:top-1 lg:top-2 bg-white hover:bg-lightestGray text-darkGray"
              aria-label="go back"
              onClick={backButtonAction}
              leftIcon={<BsChevronLeft className="fill-darkGray h-4" />}
            >
              <span className="sm:hidden lg:inline text-darkGray">Back</span>
            </Button>
          ) : !menuHidden && !closeButtonAction ? (
            <button
              onClick={onSidebarOpen}
              className="hover:bg-lightestGray w-10 h-8 mr-5 absolute left-3"
              aria-label={'open menu'}
            >
              <BsList className="fill-darkGray h-10 w-8 " />
            </button>
          ) : null}

          {logoLinkDisabled ? (
            <Image
              src={logo}
              alt="logo"
              className="sm:w-[150px] lg:w-[200px] sm:h-[33.3px] lg:h-[44.4px]"
              width={150}
              height={33.3}
            />
          ) : (
            <Link href={'/'}>
              <Image
                src={logo}
                alt="logo"
                className="sm:w-[150px] lg:w-[200px] sm:h-[33.3px] lg:h-[44.4px]"
                width={150}
                height={33.3}
              />
            </Link>
          )}
          {router.pathname === '/' && !loading && !experimentLoading && (
            <Button
              size="sm"
              onClick={() => {
                router.push(
                  hasVoucher ? '/redeem' : hasNewBookAgain ? '/book' : `/new`,
                );
              }}
              className="absolute right-3 sm:top-[10px] lg:top-[15px]"
            >
              Book
            </Button>
          )}
          {closeButtonAction ? (
            <button
              aria-label="Close page"
              className="absolute top-[13px] lg:top-[17px] right-3 flex hover:bg-lightGray rounded p-2"
              onClick={closeButtonAction}
            >
              <Icon name="x" size={16} color="black" />
            </button>
          ) : null}
        </header>
      </div>
    </>
  );
};
export default Header;
