interface BlockTitleProps {
  text: string;
  size?: 'md' | 'lg';
  variant?: 'default' | 'v2';
}

const BlockTitle = ({
  text,
  variant = 'default',
  size = 'md',
}: BlockTitleProps) => {
  if (variant === 'v2') {
    let className;

    switch (size) {
      case 'md':
        className = 'text-xl font-medium';
        break;
      case 'lg':
        className = 'text-2xl font-bold';
        break;
    }
    return <h2 className={className}>{text}</h2>;
  }

  return (
    <div className="mb-6">
      <h2 className="text-xl font-bold">{text}</h2>
      <div className="w-20 border-2 border-yellow rounded-sm mt-1" />
    </div>
  );
};

export default BlockTitle;
