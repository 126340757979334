import { HTMLAttributes } from 'react';
import clsx from 'clsx';

interface TileProps extends HTMLAttributes<HTMLDivElement> {
  variant?: 'default' | 'v2';
}

// Simple box with padding and a white background for top-level page sections
export default function Tile({ variant = 'default', ...props }: TileProps) {
  const tileStyles = clsx(
    `bg-white sm:rounded-none lg:rounded-2xl`,
    variant === 'default' && 'p-4',
    variant === 'v2' && 'shadow py-6 px-4 lg:py-10 lg:px-7',
    !props.className?.includes('overflow-') && 'overflow-visible',
    props.className,
  );

  return (
    <div {...props} className={tileStyles}>
      {props.children}
    </div>
  );
}
